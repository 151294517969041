import React, { useState } from 'react';
import { useRef } from "react";
import { NavLink, Link } from 'react-router-dom';
import {  FaTimes } from "react-icons/fa";
import "../CSS/Navbar.css";
import WebSiteLogo from '../img/logowebsite.png'; // Fixed import path
// import { MdHeight } from 'react-icons/md';
import Hambrgr from '../img/Hampbrger.png'
const Navbar = () => {
  const navRef = useRef();
  const [activeLink, setActiveLink] = useState('Home');
  const handleLinkClick = (activeLink) => {
    setActiveLink(activeLink);
  };

  const showNavbar = () => {
    navRef.current.classList.toggle(
      "responsive_nav"
    );
  };


  return (
    <header className='nav-links '>

      <Link to="/">
        <div className="logo">
          <img alt="Website Logo" src={WebSiteLogo} />
        </div>
      </Link>

      <nav ref={navRef}>
        <a href="">
          <NavLink
            exact
            to="/"
            onClick={() => handleLinkClick('Home')}>
            Home
          </NavLink>
        </a>

        <a href="">
          <NavLink to="/Carrier" onClick={() => handleLinkClick('Carrier')}>
            Career
          </NavLink>
        </a>

        <a href="">
          <NavLink to="/Aboutus" onClick={() => handleLinkClick('About')}>
            About
          </NavLink>
        </a>

        <a href="">
          <NavLink to="/Services" onClick={() => handleLinkClick('Services')}>
            Services
          </NavLink>
        </a>

        <a href="">
          <NavLink to="/Contactus" onClick={() => handleLinkClick('Contact')}>
            Contact
          </NavLink>
        </a>

        {/* <a href="">
          <NavLink to="/Books" onClick={() => handleLinkClick('Books')}>
            Book Store
          </NavLink>
        </a> */}



        <button
          className="nav-btn nav-close-btn"
          onClick={showNavbar}>
          <FaTimes />
          <h1 style={{ fontSize: '1.2rem' }}>Close</h1>
        </button>
      </nav>
      <button
        className="nav-btn"
        onClick={showNavbar}>
          <img style={{height:'3rem',width:'3rem'}} src={Hambrgr}/><span style={{color:"white",fontSize:'1.5rem',fontWeight:'bold'}}>Menu</span>
        {/* <FaBars />Menu */}
      </button>
    </header>
  );
}

export default Navbar;


